import React from "react";
import { useOverrides } from "@quarkly/components";
import { Link, Box, Text, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 30px 0",
	"background": "--color-darkL2",
	"quarkly-title": "Footer-16"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 0px 50px 0px",
			"md-margin": "0px 0px 45px 0px",
			"lg-flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "80%",
			"lg-width": "100%",
			"justify-content": "flex-end",
			"align-items": "center",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start",
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"md-display": "flex",
			"md-grid-gap": "24px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 50px 0px 10px",
			"lg-align-items": "flex-start",
			"justify-content": "center",
			"display": "grid",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"md-justify-content": "flex-start",
			"sm-margin": "0px 0 30px 0",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "8px 0",
			"sm-grid-template-columns": "1fr",
			"md-display": "flex",
			"md-grid-gap": "16px",
			"md-margin": "0px 50px 0px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"href": "/index",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"children": "Главная"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 35px 0px 0px",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "/about",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 35px 0px 0px",
			"children": "Наши услуги"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--light",
			"href": "/contact-us",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"children": "Контакты"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"justify-content": "space-between",
			"padding": "30px 0px 0px 0px",
			"border-width": "1px 0 0 0",
			"border-style": "solid",
			"border-color": "#2c3339",
			"lg-flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"lg-margin": "0px 0px 20px 0px",
			"sm-flex-direction": "column"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 50px 0px 0px",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 18px/1.2 --fontFamily-sansHelvetica",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"letter-spacing": "1px",
			"children": "Связаться с нами"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"href": "tel:+7 778 898 5499",
			"color": "#c3c8d0",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "inline-block",
			"text-align": "center",
			"margin": "0px 0px 5px 0px",
			"hover-color": "white",
			"children": "+7 778 898 5499"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"href": "mailto:contacts@zestfulwanderer.com",
			"color": "#c3c8d0",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "inline-block",
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"hover-color": "white",
			"children": "contacts@zestfulwanderer.com"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 18px/1.2 --fontFamily-sansHelvetica",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"letter-spacing": "1px",
			"children": "Адрес"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#c3c8d0",
			"font": "normal 300 16px/1.7 --fontFamily-sans",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": <>
				ул. Казыбека Би 155, 
				<br />
				Алматы 050000, Казахстан
			</>
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-align-self": "stretch",
			"sm-flex-direction": "column"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"color": "#c3c8d0",
			"md-margin": "0px 0px 0 0px",
			"md-width": "100%",
			"margin": "0 30px 0 0px",
			"children": "zestfulwanderer.com © 2024 Все права защищены."
		}
	}
};

const TukazaeFooter = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box3")}>
			<Box {...override("box4")}>
				<Box {...override("box5")}>
					<Text {...override("text")} />
					<Link {...override("link4")} />
					<Link {...override("link5")} />
				</Box>
				<Box {...override("box6")}>
					<Text {...override("text1")} />
					<Text {...override("text2")} />
				</Box>
			</Box>
			<Box {...override("box7")}>
				<Text {...override("text3")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(TukazaeFooter, { ...Section,
	defaultProps,
	overrides
});
export default TukazaeFooter;